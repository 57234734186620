<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="fa fa-picture-o"></i>CoreUI Icons <b-badge variant="info">New</b-badge>
        <div class="card-header-actions">
          <a href="https://github.com/coreui/coreui-icons" rel="noreferrer noopener" target="_blank" class="card-header-action">
            <small class="text-muted">Github</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row class="text-center">
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-account-logout icons font-2xl d-block mt-4"></i>
            <div>account-logout</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-action-redo icons font-2xl d-block mt-4"></i>
            <div>action-redo</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-action-undo icons font-2xl d-block mt-4"></i>
            <div>action-undo</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-align-center icons font-2xl d-block mt-4"></i>
            <div>align-center</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-align-left icons font-2xl d-block mt-4"></i>
            <div>align-left</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-align-right icons font-2xl d-block mt-4"></i>
            <div>align-right</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-arrow-bottom icons font-2xl d-block mt-4"></i>
            <div>arrow-bottom</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-arrow-left icons font-2xl d-block mt-4"></i>
            <div>arrow-left</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-arrow-right icons font-2xl d-block mt-4"></i>
            <div>arrow-right</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-arrow-top icons font-2xl d-block mt-4"></i>
            <div>arrow-top</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-ban icons font-2xl d-block mt-4"></i>
            <div>ban</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-basket-loaded icons font-2xl d-block mt-4"></i>
            <div>basket-loaded</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-bell icons font-2xl d-block mt-4"></i>
            <div>bell</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-bold icons font-2xl d-block mt-4"></i>
            <div>bold</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-bookmark icons font-2xl d-block mt-4"></i>
            <div>bookmark</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-briefcase icons font-2xl d-block mt-4"></i>
            <div>briefcase</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-british-pound icons font-2xl d-block mt-4"></i>
            <div>british-pound</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-brush icons font-2xl d-block mt-4"></i>
            <div>brush</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-calculator icons font-2xl d-block mt-4"></i>
            <div>calculator</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-calendar icons font-2xl d-block mt-4"></i>
            <div>calendar</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-cart icons font-2xl d-block mt-4"></i>
            <div>cart</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-chart icons font-2xl d-block mt-4"></i>
            <div>chart</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-check icons font-2xl d-block mt-4"></i>
            <div>check</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-chevron-bottom icons font-2xl d-block mt-4"></i>
            <div>chevron-bottom</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-chevron-left icons font-2xl d-block mt-4"></i>
            <div>chevron-left</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-chevron-right icons font-2xl d-block mt-4"></i>
            <div>chevron-right</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-chevron-top icons font-2xl d-block mt-4"></i>
            <div>chevron-top</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-circle-check icons font-2xl d-block mt-4"></i>
            <div>circle-check</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-circle-x icons font-2xl d-block mt-4"></i>
            <div>circle-x</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-cloud icons font-2xl d-block mt-4"></i>
            <div>cloud</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-cloud-download icons font-2xl d-block mt-4"></i>
            <div>cloud-download</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-cloud-upload icons font-2xl d-block mt-4"></i>
            <div>cloud-upload</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-code icons font-2xl d-block mt-4"></i>
            <div>code</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-cog icons font-2xl d-block mt-4"></i>
            <div>cog</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-comment-square icons font-2xl d-block mt-4"></i>
            <div>comment-square</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-credit-card icons font-2xl d-block mt-4"></i>
            <div>credit-card</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-cursor icons font-2xl d-block mt-4"></i>
            <div>cursor</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-dashboard icons font-2xl d-block mt-4"></i>
            <div>dashboard</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-delete icons font-2xl d-block mt-4"></i>
            <div>delete</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-dollar icons font-2xl d-block mt-4"></i>
            <div>dollar</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-drop icons font-2xl d-block mt-4"></i>
            <div>drop</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-envelope-closed icons font-2xl d-block mt-4"></i>
            <div>envelope-closed</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-envelope-letter icons font-2xl d-block mt-4"></i>
            <div>envelope-letter</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-envelope-open icons font-2xl d-block mt-4"></i>
            <div>envelope-open</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-euro icons font-2xl d-block mt-4"></i>
            <div>euro</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-file icons font-2xl d-block mt-4"></i>
            <div>file</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-globe icons font-2xl d-block mt-4"></i>
            <div>globe</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-graph icons font-2xl d-block mt-4"></i>
            <div>graph</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-home icons font-2xl d-block mt-4"></i>
            <div>home</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-inbox icons font-2xl d-block mt-4"></i>
            <div>inbox</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-info icons font-2xl d-block mt-4"></i>
            <div>info</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-italic icons font-2xl d-block mt-4"></i>
            <div>italic</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-justify-center icons font-2xl d-block mt-4"></i>
            <div>justify-center</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-justify-left icons font-2xl d-block mt-4"></i>
            <div>justify-left</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-justify-right icons font-2xl d-block mt-4"></i>
            <div>justify-right</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-laptop icons font-2xl d-block mt-4"></i>
            <div>laptop</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-layers icons font-2xl d-block mt-4"></i>
            <div>layers</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-lightbulb icons font-2xl d-block mt-4"></i>
            <div>lightbulb</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-list icons font-2xl d-block mt-4"></i>
            <div>list</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-location-pin icons font-2xl d-block mt-4"></i>
            <div>location-pin</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-lock-locked icons font-2xl d-block mt-4"></i>
            <div>lock-locked</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-lock-unlocked icons font-2xl d-block mt-4"></i>
            <div>lock-unlocked</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-magnifying-glass icons font-2xl d-block mt-4"></i>
            <div>magnifying-glass</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-map icons font-2xl d-block mt-4"></i>
            <div>map</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-monitor icons font-2xl d-block mt-4"></i>
            <div>monitor</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-moon icons font-2xl d-block mt-4"></i>
            <div>moon</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-note icons font-2xl d-block mt-4"></i>
            <div>note</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-options icons font-2xl d-block mt-4"></i>
            <div>options</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-paperclip icons font-2xl d-block mt-4"></i>
            <div>paperclip</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-pencil icons font-2xl d-block mt-4"></i>
            <div>pencil</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-people icons font-2xl d-block mt-4"></i>
            <div>people</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-phone icons font-2xl d-block mt-4"></i>
            <div>phone</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-pie-chart icons font-2xl d-block mt-4"></i>
            <div>pie-chart</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-print icons font-2xl d-block mt-4"></i>
            <div>print</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-puzzle icons font-2xl d-block mt-4"></i>
            <div>puzzle</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-rss icons font-2xl d-block mt-4"></i>
            <div>rss</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-screen-desktop icons font-2xl d-block mt-4"></i>
            <div>screen-desktop</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-screen-smartphone icons font-2xl d-block mt-4"></i>
            <div>screen-smartphone</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-settings icons font-2xl d-block mt-4"></i>
            <div>settings</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-share icons font-2xl d-block mt-4"></i>
            <div>share</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-shield icons font-2xl d-block mt-4"></i>
            <div>shield</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-sort-ascending icons font-2xl d-block mt-4"></i>
            <div>sort-ascending</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-sort-descending icons font-2xl d-block mt-4"></i>
            <div>sort-descending</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-speech icons font-2xl d-block mt-4"></i>
            <div>speech</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-speedometer icons font-2xl d-block mt-4"></i>
            <div>speedometer</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-star icons font-2xl d-block mt-4"></i>
            <div>star</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-sun icons font-2xl d-block mt-4"></i>
            <div>sun</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-tablet icons font-2xl d-block mt-4"></i>
            <div>tablet</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-tags icons font-2xl d-block mt-4"></i>
            <div>tags</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-task icons font-2xl d-block mt-4"></i>
            <div>task</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-thumb-down icons font-2xl d-block mt-4"></i>
            <div>thumb-down</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-thumb-up icons font-2xl d-block mt-4"></i>
            <div>thumb-up</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-trash icons font-2xl d-block mt-4"></i>
            <div>trash</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-underline icons font-2xl d-block mt-4"></i>
            <div>underline</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-user icons font-2xl d-block mt-4"></i>
            <div>user</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-user-female icons font-2xl d-block mt-4"></i>
            <div>user-female</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-user-follow icons font-2xl d-block mt-4"></i>
            <div>user-follow</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-user-unfollow icons font-2xl d-block mt-4"></i>
            <div>user-unfollow</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-wrench icons font-2xl d-block mt-4"></i>
            <div>wrench</div>
          </b-col>
          <b-col cols="6" sm="4" md="3" xl="2">
            <i class="cui-yen icons font-2xl d-block mt-4"></i>
            <div>yen</div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'CoreUIIcons'
}
</script>
